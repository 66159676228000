/**
 * 20200923 RP 126-4845 added sign up module part
 * 20201007 GC 173-4850 added statuscode 14.
 * 20201013 GC 173-4850 added dynamic error page, handled redirect
 * 20201125 CV 173-4850 Handled invalid link error.
 * 20201118 GC 190-4871 added Added permissions for landing page
 * 20201211 CV 173-4845 Fixed QA concerns.
 * 20201217 CV 173-4845 Fixed QA concerns.
 * 20210111 CV 173-4845 Implemented to focus on next field when pressing enter button.
 * 20210122 PU 249-4903 changed error string to Error object.
 * 20210126 GC 190-4871 Added inactive company redirect
 * 20210219 PU 291-4911 added common error handling
 * 20201217 CV 173-4845 Fixed QA concerns.
 * 20210112 GC 189-4864 Added empStatus param to initial login
 * 20210216 GC 189-4864 Changed initial login
 * 20210112 GC 189-4864 Added empStatus param to initial login
 * 20231122 SS XENOV2-434 Added createUserPassword to cater employees to signup
 * 20231215 SS XENOV2-425 error notification without routing to a page
 */

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, EmailValidator } from '@angular/forms';
import { CustomValidators } from './custom-validators';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { Location } from '@angular/common';
import { ErrorPageService } from '../../../../core/services/error-page.service';
import * as $ from 'jquery';
import { appConfig } from 'src/environments/environment';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public frmSignup: FormGroup;
  token: string;
  returnUrl: string;
  error = '';
  tabIndex = 1;
  loading = false;
  submitted = false;
  userDetailsDto: any = {};
  showForm = false; // true if no redirection needed with tiken validation
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private notificationUtils: NotificationUtils,
    private authenticationService: AuthenticationService,
    private location: Location,
    private errorService: ErrorPageService) {
  }

  ngOnInit() {
    this.frmSignup = this.createSignupForm(); // create signup form
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token'); // read token from route param
    });

    this.getEmail(this.token); // get email by provided token

    this.frmSignup.get('password').valueChanges.subscribe(value => {
      if (!this.frmSignup.controls.password.invalid) {
        this.frmSignup.controls.confirmPassword.enable(); // enable confirm password field
      }
      else {
        this.frmSignup.controls.confirmPassword.disable(); // disable confirm password field
      }
    });

    //Move focus on next field when enter is pressed
    $(document).on('keypress', 'input,select', function (e) {
      if (e.key == "Enter") {
        e.preventDefault();
        var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
       // console.log($next.length);
       // if (!$next.length) {
       //   $next = $('[tabIndex=1]');
      //  }
        $next.focus();
      }
    });

    $('#confirmPassword').keyup(function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        $('form').submit();
      }
    });

  }

  /**
   * Create signup form
   */
  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),

            Validators.minLength(8),


          ])
        ],
        confirmPassword: [{ value: null, disabled: true }, Validators.compose([Validators.required])],


      },
      {
        // check whether password and confirm password match
        validator: [CustomValidators.passwordMatchValidator, CustomValidators.equaltoUsernameValidator]
      },


    );
  }

  /**
   * 20231122 SS XENOV2-434 5169 Added createUserPassword(passwordCreationData, portalName)
   * 
   * Create password
   */
  passwordCreation() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    const portalName = url.split('.' + host + '.')[0]; // Extract string before .xenohr.
    this.submitted = true;
    this.loading = true;
    if (portalName !== url) { // Portal name exists, do portal login
      const passwordCreationData = {
        token: this.token,
        password: this.frmSignup.get('password').value,
        email: this.frmSignup.get('email').value,
        modBy: this.frmSignup.get('email').value,
        portalName: portalName
      };
      this.createUserPassword(passwordCreationData, portalName);
    } else {
      // Update password if portal name doesn't exist
      const passwordCreationData = {
        token: this.token,
        password: this.frmSignup.get('password').value,
        email: this.frmSignup.get('email').value,
        modBy: this.frmSignup.get('email').value
      };
      this.createAdminPassword(passwordCreationData);
    }
  }

  createAdminPassword(passwordCreationData) {
    this.apiService.updatePassword(passwordCreationData).subscribe(
      (response) => {
        // Handle response based on API status codes
        if (response.body.apiStatusCode === 2) {
          // Logic for successful password update
          const loginData = {
            token: this.token,
            username: this.frmSignup.get('email').value,
            password: this.frmSignup.get('password').value
          };
          // Subscribe to logUser for authentication
          this.authenticationService.logUser(loginData).subscribe(
            (res) => {
              // Handle authentication response
              if (res.apiStatusCode === 0) {
                // Logic for successful authentication
                this.loading = false;
                const permissionRes = res.additionalParams;
                let permissions = [];
                permissions = permissionRes.permissions;
                // Construct user object with available data
                const user = {
                  cmpCode: res.data.cmpCode,
                  firstName: '',
                  id: '',
                  lastName: '',
                  permissions: permissions, // set available permissions
                  role: '',
                  token: res.data.token,
                  jwtToken:res.data.jwtToken,
                  username: res.data.username
                };
                // Perform initial login
                this.authenticationService.initialLogin(user, '');
              }
            },
            // Error handling for logUser authentication service
            (error) => {
              this.loading = false;
              if (error.errorCode === 409) {
                this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
              } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
                this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
              }
            }
          );
        } else if (response.body.apiStatusCode === 15) {
          // Handle inactive link error
          this.notificationUtils.showErrorMessage(undefined, response.body.message);
        } else if (response.body.apiStatusCode === 14) {
          // Handle already created password, redirect to login
          this.router.navigate(['/login']);
        }
      },
      // Error handling for updatePassword API
      (error) => {
        if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

  createUserPassword(passwordCreationData, portalName) {
    this.apiService.createUserPassword(passwordCreationData).subscribe(
      (response) => {
        // Handle response based on API status codes
        if (response.body.apiStatusCode === 2) {
          // Logic for successful creation
          // Create object with available data
          const loginData = {
            portalName: portalName,
            username: this.frmSignup.get('email').value,
            password: this.frmSignup.get('password').value
          };
  
          // Subscribe to authentication service to get user token
          this.authenticationService.getUserToken(loginData).subscribe(
            (res) => {
              // Handle authentication response
              if (res.apiStatusCode === 0) {
                // Logic for successful authentication
                this.loading = false;
                const permissionRes = res.additionalParams;
                let permissions = [];
                permissions = permissionRes.permissions;
                // Construct user object with available data
                const user = {
                  cmpCode: res.data.cmpCode,
                  firstName: '',
                  id: '',
                  lastName: '',
                  permissions: permissions, // set available permissions
                  role: '',
                  token: res.data.jwtToken,
                  jwtToken:res.data.jwtToken,
                  username: res.data.username,
                  companyName:  res.data.companyName
                };
                const empStatus = res.data.empStatus;
                // Perform initial login
                this.authenticationService.initialLogin(user, empStatus);
              }
            },
            // Error handling for authentication service
            (error) => {
              this.loading = false;
              if (error.errorCode === 409) {
                this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
              } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
                this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
              }
            }
          );
        } else if (response.body.apiStatusCode === 15) {
          // Handle inactive link error
          this.notificationUtils.showErrorMessage(undefined, response.body.message);
        } else if (response.body.apiStatusCode === 14) {
          // Handle already created password, redirect to login
          this.router.navigate(['/login']);
        }
      },
      // Error handling for createUserPassword API
      (error) => {
        if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

  //submit the form when the user hits the enter button in the last form field.
  keyDownFunction(event) {
    if (event.keyCode === 13 && !this.frmSignup.invalid) {
      this.passwordCreation();
    }
  }

  /**
   * Get the user email by provided token from email url
   */
  getEmail(token: string) {

    this.apiService.getEmail(this.token).subscribe(
      (response) => {
        if (response.apiStatusCode === 17) { // If user has portal link
          this.notificationUtils.showErrorMessage(undefined, "Invalid Link");
        } else if (response.apiStatusCode === 15) { // If link is inactive
          this.notificationUtils.showErrorMessage(undefined, "Invalid Link");
        } else  if (response.apiStatusCode === 14) { // If terms and conditions pending
          this.router.navigateByUrl('login?token='+this.token);
        } else {
          this.userDetailsDto = response.data;
          console.log("client ip : " + response.ip);
          if (localStorage.getItem('user')) {
            this.router.navigateByUrl('login?token='+this.token);
          } else{
            this.showForm = true;
            this.frmSignup.get('email').setValue(response.data.email); // set response email to form
          }

        }
      },
      (error) => {
        if (error.errorCode === 404) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }else{
          this.notificationUtils.showErrorMessage(undefined, "Invalid Link");
        }
      });

  }

  submit(){

  }

  keytab(event){

  }
}




